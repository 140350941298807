import "./CardDesignSelector.scss";
import { useEffect, useLayoutEffect, useRef } from "preact/compat";
import { CardDesign } from "../CardDesign.tsx";
import { register, type SwiperContainer } from "swiper/element/bundle";
import { activeProduct, cardsBySet } from "../models/products.ts";
import classNames from "classnames";
import { useComputed, useSignalEffect } from "@preact/signals";
import type { GetImageResult } from "astro";
import type { SwiperOptions } from "swiper/types";

const swiperParams: SwiperOptions = {
  injectStyles: [
    `
    .swiper-button-next, .swiper-button-prev {
        --swiper-navigation-top-offset: 37px;
        color: white;
        width: 28px;
        height: 24px;
        border-radius: 12px;
        background-color: #1F1F1F;
        svg {
        width: 6px;
        }
    }
    .swiper-button-disabled {
        visibility: hidden;
    }
  `,
  ],
  slidesPerView: "auto",
  navigation: true,
  cssMode: true,
  breakpoints: {
    768: {
      cssMode: false,
    },
  },
  centeredSlides: false,
  speed: 800,
  watchSlidesProgress: true,
};

interface Props {
  labelText: string;
  images: Record<string, { images: GetImageResult[]; preview: GetImageResult }>;
  variant: "small" | "regular";
}

const SliderSkeleton = ({ skeletonsAmount = 1 }) => {
  return (
    <div className="skeleton__wrapper">
      {[...Array(skeletonsAmount)].map(() => (
        <div className="skeleton__item"></div>
      ))}
    </div>
  );
};

export const CardDesignSelector = ({
  labelText,
  images,
  variant = "regular",
}: Props) => {
  const swiperRef = useRef<SwiperContainer | null>(null);

  const isLoading = useComputed(() => cardsBySet.value.length === 0);

  useEffect(() => {
    register();
    Object.assign(swiperRef.current!, swiperParams);
    swiperRef.current!.initialize();
  }, []);

  useSignalEffect(() => {
    if (isLoading.value) return;

    const activeIndex = cardsBySet.value.findIndex(
      (product) => product.id === activeProduct.value?.id,
    );
    if (activeIndex !== -1) {
      swiperRef.current!.swiper.slideTo(activeIndex);
    }
  });

  useSignalEffect(() => {
    cardsBySet.value; // subsription to changes
    swiperRef.current?.swiper.update(); // if slides are chaned we need to notify swiper
  });

  const cardDesignName = useComputed(() => activeProduct.value?.subtitle ?? "");

  return (
    <div className="сard-design">
      <span className="card-design__label">
        {labelText}
        <span className="card-design__name">{cardDesignName}</span>
      </span>
      {
        <swiper-container
          init="false"
          className={classNames(
            "card-design__wrapper",
            {
              ["card-design__wrapper_small"]: variant === "small",
            },
          )}
          ref={swiperRef}
        >
          {isLoading.value ? <SliderSkeleton /> : null}
          {cardsBySet.value.map((product) => (
            <swiper-slide key={product.id} className="card-design__item">
              <CardDesign images={images} product={product} />
            </swiper-slide>
          ))}
        </swiper-container>
      }
    </div>
  );
};
