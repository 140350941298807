import type { MedusaProduct } from "../../types";
import { activeProduct, updatePrice } from "./models/products.ts";
import { useComputed } from "@preact/signals";
import { useSignalEffect } from "@preact/signals";
import type { GetImageResult } from "astro";
import { getDiscountForProduct } from "./models/discounts.ts";
import s from "./CardDesign.module.scss";

interface Props {
  product: MedusaProduct;
  images: Record<string, { images: GetImageResult[]; preview: GetImageResult }>;
}

export const CardDesign = ({ product, images }: Props) => {
  const handleCardChanged = () => {
    activeProduct.value = product;
  };

  const discountsAmount = getDiscountForProduct(product);
  const checked = useComputed(() => activeProduct.value?.id === product.id);

  useSignalEffect(updatePrice);

  return (
    <>
      <input
        class="selector__card-input"
        type="radio"
        name="card-design"
        value={product.id}
        aria-label={`${product.title} card design`}
        onClick={handleCardChanged}
        id={product.id}
        checked={checked}
      />
      <label for={product.id} class="selector__card-label">
        <img
          src={images?.[product.id]?.preview.src}
          height={44}
          width={78}
          alt={`${product.title} card design`}
        />
        {discountsAmount ? (
          <div className={s.discount}>{`-${discountsAmount}%`}</div>
        ) : null}
      </label>
    </>
  );
};
